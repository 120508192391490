import { CurrentUserDto } from '../../dtos/current-user/current-user.dto';
import { CurrentUserModel } from '../../models/current-user/current-user.model';

export class CurrentUserCreator {
  public static create(dto: CurrentUserDto): CurrentUserModel {
    return {
      username: dto.username,
    };
  }
}
