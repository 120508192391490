import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UsersHttpService } from '../users-http/users-http.service';
import { CurrentUserCreator } from '../../creators/current-user/current-user.creator';
import { UsersListDataPageDtoCreator } from '../../creators/users-list-data-page-dto/users-list-data-page-dto.creator';
import { UsersListItemCreator } from '../../creators/users-list-item/users-list-item.creator';
import { ListCreator } from '../../../shared/creators/list/list.creator';
import { CurrentUserDto } from '../../dtos/current-user/current-user.dto';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { UsersListItemDto } from '../../dtos/users-list-item/users-list-item.dto';
import { UsersListDataPageDto } from '../../dtos/users-list-data-page/users-list-data-page.dto';
import { CurrentUserModel } from '../../models/current-user/current-user.model';
import { UsersListItemModel } from '../../models/users-list-item/users-list-item.model';
import { UsersListDataPageModel } from '../../models/users-list-data-page/users-list-data-page.model';
import { ListModel } from '../../../shared/models/list/list.model';
import { UsersListItemDetailsDto } from '../../dtos/users-list-item-details/users-list-item-details.dto';
import { UsersListItemDetailsCreator } from '../../creators/users-list-item-details/users-list-item-details.creator';
import { UsersListItemDetailsModel } from '../../models/users-list-item-details/users-list-item-details.model';
import { UserAddEditFormModel } from '../../models/user-add-edit-form/user-add-edit-form.model';
import { UserAddEditFormDtoCreator } from '../../creators/user-add-edit-form-dto/user-add-edit-form-dto.creator';
import { UserAddEditFormDto } from '../../dtos/user-add-edit-form/user-add-edit-form.dto';
import { UserFormDetailsDto } from '../../dtos/user-form-details/user-from-details.dto';
import { UserFormDetailsCreator } from '../../creators/user-form-details/user-form-details.creator';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private usersHttpService: UsersHttpService) {}

  public getCurrentUser(): Observable<CurrentUserModel> {
    return this.usersHttpService.getCurrentUser().pipe(map((dto: CurrentUserDto) => CurrentUserCreator.create(dto)));
  }

  public getUsersList(usersListFilters: UsersListDataPageModel): Observable<ListModel<UsersListItemModel>> {
    const usersListRequestDto: UsersListDataPageDto = UsersListDataPageDtoCreator.create(usersListFilters);

    return this.usersHttpService.getUsersList(usersListRequestDto).pipe(
      map((listDto: ListDto<UsersListItemDto>) => {
        const items: Array<UsersListItemModel> = listDto.items.map((usersListItemDto: UsersListItemDto) =>
          UsersListItemCreator.create(usersListItemDto)
        );

        return ListCreator.create<UsersListItemDto, UsersListItemModel>(listDto, items);
      })
    );
  }

  public getUserDetails(userId: string): Observable<UsersListItemDetailsModel> {
    return this.usersHttpService
      .getUserDetails(userId)
      .pipe(map((usersListItemDetailsDto: UsersListItemDetailsDto) => UsersListItemDetailsCreator.create(usersListItemDetailsDto)));
  }

  public getUserFormDetails(userId: string): Observable<UserAddEditFormModel> {
    return this.usersHttpService
      .getUserFormDetails(userId)
      .pipe(map((userAddEditFormDto: UserFormDetailsDto) => UserFormDetailsCreator.create(userAddEditFormDto)));
  }

  public addUser(userDetails: UserAddEditFormModel): Observable<void> {
    const userDetailsFormDto: UserAddEditFormDto = UserAddEditFormDtoCreator.create(userDetails);

    return this.usersHttpService.addUser(userDetailsFormDto);
  }

  public updateUserDetails(userDetails: UserAddEditFormModel, userId: string): Observable<void> {
    const userDetailsFormDto: UserAddEditFormDto = UserAddEditFormDtoCreator.create(userDetails);

    return this.usersHttpService.updateUserDetails(userDetailsFormDto, userId);
  }

  public deleteUser(userId: string): Observable<void> {
    return this.usersHttpService.deleteUser(userId);
  }
}
