import { UserActionsDto } from '../../dtos/user-actions/user-actions.dto';
import { UsersListItemDetailsDto } from '../../dtos/users-list-item-details/users-list-item-details.dto';
import { UsersListItemDetailsModel } from '../../models/users-list-item-details/users-list-item-details.model';
import { UserActionsModel } from '../../models/user-actions/user-actions.model';

export class UsersListItemDetailsCreator {
  public static create(dto: UsersListItemDetailsDto): UsersListItemDetailsModel {
    return {
      createdAt: dto.created_at,
      updatedAt: dto.updated_at,
      lastLoggedAt: dto.last_logged_at,
      blockageReason: dto.blockage_reason,
      actions: UsersListItemDetailsCreator.createUserActions(dto.actions),
    };
  }

  private static createUserActions(dto: UserActionsDto): UserActionsModel {
    return {
      delete: dto.delete,
      update: dto.update,
    };
  }
}
