import { UserAddEditFormModel } from '../../models/user-add-edit-form/user-add-edit-form.model';
import { UserAddEditDetailsFormDto } from '../../dtos/user-add-edit-details-form/user-add-edit-details-form.dto';

export class UserAddEditDetailsFormDtoCreator {
  public static create(model: UserAddEditFormModel): UserAddEditDetailsFormDto {
    return {
      ...(model.userName && { username: model.userName }),
      ...(model.description && { description: model.description }),
      ...(model.contactPerson && { contact_person: model.contactPerson }),
      ...(model.phone && { phone: model.phone }),
    };
  }
}
