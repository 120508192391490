import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { ModalComponent } from './components/modal/modal.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';

@NgModule({
  declarations: [ModalComponent, PopupModalComponent],
  exports: [ModalComponent],
  imports: [CommonModule, TranslateModule],
})
export class ModalModule {}
